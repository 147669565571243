.experience {
  margin-top: calc(var(--header-height) + 0.5rem);
}

.experience__container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px 40px 0px 40px;
  transition: all ease-in-out 0.3s;
}

.experience__course {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 3rem;
  text-align: left;
}

.experience__img-container {
  display: flex;
  justify-content: center;
  align-items: top;
  margin-top: 1.5rem;
}

.experience__img {
  width: 100px;
  height: min-content;
}

.experience__course-info h5 {
  font-size: 17px;
  margin-top: 2rem;
}

.experience__course-info .date {
  float: left;
}

.experience__course-info .location {
  float: right;
  padding-right: 2px;
}

.experience__coursework {
  margin-top: 0;
}

.experience__coursework h6 {
  font-size: 12px;
}

.experience__coursework ul {
  columns: 2;
  padding-left: 1.5rem;
  text-align: left;
  list-style-type: circle;
}

/*=============== BREAKPOINTS ===============*/
@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .experience__container {
    padding: 20px 40px 20px 40px;
  }

  .experience__coursework {
    margin-top: 2rem;
  }

  .experience {
    margin-top: 1rem;
  }
  
  .experience__course {
    margin: 0;
  }

  .experience__img {
    width: 90px;
  }

  .experience__coursework ul {
    padding-left: 0;
    columns: 1;
  }
}

@media screen and (max-width: 576px) {
  .experience__img {
    width: 110px;
  }

  .experience__course {
    grid-template-columns: 1fr;
  }

  .experience__img-container {
    justify-content: center;
  }

  .experience__course-info {
    text-align: center;
  }

  .experience__course-info h5 {
    margin-top: 0;
  }

  .experience .date,
  .experience .location {
    display: block;
    float: none;
  }
}

@media screen and (max-width: 350px) {
}
