.projects {
  margin-top: calc(var(--header-height) + 0.5rem);
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 320px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
}

.project:hover {
  border: 1px solid #000;
}

.project img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.project:hover img {
  transform: scale(1.05);
}

.project h3 {
  margin: 10px 0;
}

.project p {
  flex-grow: 1;
}

.project-link {
  flex-basis: calc(33.33% - 2rem);
  margin: 1rem 1rem;
  text-decoration: none;
  color: inherit;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: 0;
  margin-right: 0;
}

.projects-list.container {
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
}

/*=============== BREAKPOINTS ===============*/
@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {

  .project-link {
    flex-basis: calc(50% - 2rem);
  }

  .projects {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .project-link {
    flex-basis: calc(100% - 2rem);
  }
}

@media screen and (max-width: 350px) {}