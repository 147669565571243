.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 2rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    max-width: 70%;
    overflow: auto;
    position: relative;
    text-align: center;
}

.modal-content iframe {
    width: 80%;
    margin: 0 auto;
}

.modal-content h2,
.modal-content p {
    text-align: left;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.social-link {
    display: block;
    margin-top: 10px;
    text-align: center;
}

.social-link svg {
    fill: var(--title-color);
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

/*=============== BREAKPOINTS ===============*/
@media screen and (max-width: 992px) {}

@media screen and (max-width: 768px) {
    .modal-content {
        max-width: 90%;
    }

    .modal {
        top: 0rem;
    }

    .modal-content iframe {
        width: 90%;
    }
}

@media screen and (max-width: 576px) {
    .modal-content iframe {
        width: 100%;
    }

    .modal-content {
        max-width: 100%;
        padding: 25px;
    }
}

@media screen and (max-width: 350px) {}