.language__container {
  position: fixed;
  top: 10px;
  z-index: 7;
}

.language__item {
  margin: 0 4px 0 7px;
  background-color: transparent;
}

.untranslated svg {
  filter: grayscale(100%);
}

/*=============== BREAKPOINTS ===============*/
@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .language__container {
    position: absolute;
    right: 10px;
  }
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 350px) {
}
