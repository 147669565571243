.education {
  margin-top: calc(var(--header-height) + 0.5rem);
}

.education__container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 40px 40px;
  transition: all ease-in-out 0.3s;
}

.education__course {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 3rem;
  text-align: left;
}

.education__img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.education__img {
  width: 100px;
  /* height: 100px; */
}

.education__course-info h5 {
  font-size: 17px;
  margin-top: 2rem;
}

.education__course-info .date {
  float: left;
}

.education__course-info .location {
  float: right;
  padding-right: 2px;
}

.education__coursework {
  margin-top: 0;
}

.education__coursework h6 {
  font-size: 12px;
}

.education__coursework ul {
  columns: 2;
  padding-left: 1.5rem;
  text-align: left;
  list-style-type: circle;
}

/*=============== BREAKPOINTS ===============*/
@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .education__coursework {
    margin-top: 2rem;
  }

  .education {
    margin-top: 1rem;
  }
  
  .education__course {
    margin: 0;
  }

  .education__img {
    width: 90px;
  }

  .education__coursework ul {
    padding-left: 0;
    columns: 1;
  }
}

@media screen and (max-width: 576px) {
  .education__img {
    width: 110px;
  }

  .education__course {
    grid-template-columns: 1fr;
  }

  .education__img-container {
    justify-content: center;
  }

  .education__course-info {
    text-align: center;
  }

  .education__course-info h5 {
    margin-top: 0;
  }

  .education .date,
  .education .location {
    display: block;
    float: none;
  }
}

@media screen and (max-width: 350px) {
}
