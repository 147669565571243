.about {
  margin-top: calc(var(--header-height) + 0.5rem);
  flex: 1;
}

.about__container {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 3rem;
}

.about__img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__img {
  /* width: 220px; */
  width: 220px;
  border-radius: 0.5rem;
}

.about__description {
  margin-bottom: var(--mb-1-5);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {

  .about__container {
    grid-template-columns: 1fr;
  }

  .about__img-container {
    justify-content: center;
  }

  .about__data {
    text-align: left;
    padding: 0 3rem 0 3rem;
  }
}

@media screen and (max-width: 768px) {
  .about {
    margin-top: 1rem;
  }
  
  .about__data {
    padding: 0 2rem 0 2rem;
  }
}

@media screen and (max-width: 576px) {
  .about__data {
    padding: 0 1rem 0 1rem;
  }
}

@media screen and (max-width: 350px) {
  .about__data {
    padding: 0;
  }
}
