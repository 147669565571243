.footer {
  background-color: var(--container-color);
  height: var(--footer-height);
}

/*=============== BREAKPOINTS ===============*/
@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .footer {
    background-color: var(--container-color);
  }
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 350px) {
}
